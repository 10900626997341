import React, { useContext, useState } from 'react'
import navigate from '../utils/navigate'
import { Context as LeaveContext } from '../components/LeaveContext'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PrivateRoute from '../components/PrivateRoute'
import LeaveRequestQuickNav from '../components/LeaveRequestQuickNav'
import getAnimationStyle from '../utils/getAnimationStyle'

export default function New_Leave_Request_Covid() {
  const { isCovidLeaveForEmployee } = useContext(LeaveContext)

  const [state, setState] = useState({
    isEmployee: null,
    Error: {},
  })
  const onSubmit = (event) => {
    event.preventDefault()

    const Errors = {}
    // Validate Data
    if (state.isEmployee !== 'ME' && state.isEmployee !== 'FM') {
      Errors.isEmployee = 'Employee or Family is not valid'
    }

    if (Object.keys(Errors).length > 0) {
      setState(prev => ({ ...prev, Error: Errors }))
      return false
    }

    // save to the store
    if (state.isEmployee === 'ME') {
      isCovidLeaveForEmployee(true)
      navigate('/New_Leave_Request_Covid_EE/');
    } else {
      isCovidLeaveForEmployee(false)
      navigate('/New_Leave_Request_Covid_Family/');
    }
  }

  const handleIsEmployeeChange = (e) => {
    e.persist()
    const { Error } = state
    delete Error.isEmployee
    setState(prev => ({ ...prev, isEmployee: e.target.value, Error }))
  }
  return (
    <PrivateRoute>
      <Layout activateLink>
        <SEO title="Covid Leave Request" />
        <div className="content-outline-998 leaveForm">
          <LeaveRequestQuickNav />
          <p>&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <div className="questionhdr">
            <p>Please complete the following questions to determine if you need to file a COVID leave request. Based on
              your responses, we will notify your manager if you can return to work immediately or need time off.</p>
          </div>
          <form
            className="LeaveFormEntry"
            onSubmit={onSubmit}
          >
            <div className={state.Error.isEmployee ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(0)}>
              <label>
                Is the request for you or a family member that resides in your household?:<br />
              </label>
              <select
                name="ctl00$Holder$Request01$ddlState"
                id="ctl00_Holder_Request01_ddlState"
                style={{ width: '7em', paddingTop: 20 }}
                onChange={handleIsEmployeeChange}
                value={state.isEmployee}
              >
                <option value="" />
                <option value="ME">ME</option>
                <option value="FM">Family Member</option>
              </select>
            </div>
            <p className="clear">&nbsp;</p>
            <input
              style={{ cursor: 'pointer' }}
              type="submit"
              id="setp1submit"
              value="Continue &raquo;"
            />
          </form>
        </div>
      </Layout>
    </PrivateRoute>
  )
}
